import types from './ActionTypes';
import Api from '../../../utilities/services/Api';


export const getArtisanCountReport = (searchCiteria) => {
    return ({
      type: types.GET_ARTISAN_COUNT_REPORT,
      payload: Api.report.artisanCountReport(searchCiteria),
    })
};  

export const resetMessages = () => {
    return ({
      type: types.RESET_MESSAGES,
    })
}; 