import React from 'react';
import { Card, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';

const Actions = props => {

    const { t } = useTranslation();
    return (
        <Card  >
            <Timeline mode={'left'} >
                {props.actions && props.actions.map((event, index) => {
                    return <Timeline.Item key={index} label={<span style={{ fontSize: '12px' }}>{event.date_evenement}</span>}>
                        <span style={{
                            fontSize: "12px",
                            fontWeight: "649"
                        }}>
                            <span>{t(`artisan.artisan-notification.notification.${event.type_evement}`)}</span>
                            <span > { event.type_evement === 'DOSSIER_SOUMI_A_VALIDATION' && event.commentaire }</span> 
                            <span style={{ color :  '#f5222d' }}> { event.type_evement === 'DOSSIER_REJETE' && event.commentaire }</span> 
                        </span>
                    </Timeline.Item>
                })}
            </Timeline>
        </Card>
    )
}

export default Actions;