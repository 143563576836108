import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getRegions = () => ({
  type: types.GET_REGIONS,
  payload: Api.referentiels.regions.getAll(),
});
export const updateRegion = (id, paylaod) => ({
  type: types.UPDATE_REGION,
  payload: Api.referentiels.regions.update(id, paylaod),
});
export const addRegion = (paylaod) => ({
  type: types.ADD_REGION,
  payload: Api.referentiels.regions.add(paylaod),
});

export const getProvinces = () => ({
  type: types.GET_PROVINCES,
  payload: Api.referentiels.provinces.getAll(),
});
export const updateProvince = (id, paylaod) => ({
  type: types.UPDATE_PROVINCE,
  payload: Api.referentiels.provinces.update(id, paylaod),
});
export const addProvince = (paylaod) => ({
  type: types.ADD_PROVINCE,
  payload: Api.referentiels.provinces.add(paylaod),
});

export const getVilles = () => ({
  type: types.GET_VILLES,
  payload: Api.referentiels.villes.getAll(),
});
export const updateVille = (id, paylaod) => ({
  type: types.UPDATE_VILLE,
  payload: Api.referentiels.villes.update(id, paylaod),
});
export const addVille = (paylaod) => ({
  type: types.ADD_VILLE,
  payload: Api.referentiels.villes.add(paylaod),
});
