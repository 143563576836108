import types from './ActionTypes';
import Api from '../../../utilities/services/Api';
import Utils from '../../../utilities/Utils/Utils';


export const getArtisanBySearchCriteria = (searchCiteria, searchMetaCriteria = Utils.PAGE_SEARCH_DEFAULT) => {
  const order = (searchMetaCriteria.sortDirection === undefined && searchMetaCriteria.sortDirection === null)
    ? false
    : searchMetaCriteria.sortDirection == 'ascend' ? 'ASC' : 'DESC';
  const metaCriteria = {
    page: searchMetaCriteria.page,
    page_size: searchMetaCriteria.pageSize,
    with_count: searchMetaCriteria.withCount,
    sort_field: searchMetaCriteria.sortField,
    sort_direction: order,
  }
  return ({
    type: types.GET_ARTISAN_BY_SEARCH_CRITERIA,
    payload: Api.artisan.getArtisanBySearchCriteia({ ...searchCiteria, search_meta_data: metaCriteria }),
  })
};

export const radiateRnaAccountById = (artisantId) => {
  return ({
    type: types.RADIAT_ARTISAN,
    payload: Api.accounts.radiateRnaAccountById(artisantId),
  })
};


export const resetMessages = () => {
  return ({
    type: types.RESET_MESSAGES_ARTISAN_DETAIL
  })
};