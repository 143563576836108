import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getAnnexe = () => ({
  type: types.GET_ANNEXE_ADMINISTRATIVE,
  payload: Api.referentiels.annexe.getAll(),
});
export const updateAnnexe = (id, paylaod) => ({
  type: types.UPDATE_ANNEXE_ADMINISTRATIVE,
  payload: Api.referentiels.annexe.update(id, paylaod),
});
export const addAnnnexe = (paylaod) => {
  return {
    type: types.ADD_ANNEXE_ADMINISTRATIVE,
    payload: Api.referentiels.annexe.add(paylaod),
  }
};