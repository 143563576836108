import types from './ActionTypes';
import Api from '../../../utilities/services/Api';
import fileDownload from 'js-file-download';
import { param } from 'jquery';


export const getArtisanBySearchCriteria = (searchCiteria, searchMetaCriteria) => {

  const order = (searchMetaCriteria.sortDirection === undefined && searchMetaCriteria.sortDirection === null)
    ? false
    : searchMetaCriteria.sortDirection == 'ascend' ? 'ASC' : 'DESC';
  const metaCriteria = {
    page: searchMetaCriteria.page,
    page_size: searchMetaCriteria.pageSize,
    with_count: searchMetaCriteria.withCount,
    sort_field: searchMetaCriteria.sortField,
    sort_direction: order,
  }
  return ({
    type: types.GET_ARTISAN_BY_SEARCH_CRITERIA,
    payload: Api.artisan.getArtisanBySearchCriteia({ ...searchCiteria, search_meta_data: metaCriteria }),
  })
};

export const rejectSubscription = (artisantId, payload) => {
  return ({
    type: types.REJECT_ARTISAN_SUBSCRIPTION,
    payload: Api.accounts.rejectSubscription(artisantId, payload),
  })
};

export const validateSubscription = (artisantId, forcerValidation) => {
  return ({
    type: types.VALIDATE_ARTISAN_SUBSCRIPTION,
    payload: Api.accounts.validateSubscription(artisantId, { forcer_validation: forcerValidation }),
  })
};

export const resetMessages = () => {
  return ({
    type: types.RESET_MESSAGES_ARTISAN_DETAIL
  })
};

export const downloadArtisanCnssReport = (params) => {
  return ({
    type: types.DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS,
    payload: Api.report.artisanCNSSReport(params).then(res => {
      const fileName = res.headers.x_file_name && res.headers.x_file_name !== ""
        ? res.headers.x_file_name
        : "attestation_artisan.pdf";
      fileDownload(res.data, fileName);
      return res;
    }),
  })
}

export const downloadCertification = (artisanId) => {
  return ({
    type: types.DOWNLOAD_CERTIFICATION,
    payload: Api.artisan.getCertification(artisanId).then( res => {
      const fileName = res.headers.x_file_name &&  res.headers.x_file_name !== "" 
          ? res.headers.x_file_name
          : "certification_artisan.pdf" ;

      fileDownload(res.data, fileName);
      return res;
    }),
  })
};

export const clearMessages = () => {
  return ({
    type: types.CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES,
  })
};
