import { Row, Space } from "antd";
import React, { useEffect, useState } from "react"
import ArtisanList from "./components/ArtisanList/ArtisanList";
import ArtisanSearchForm from "./components/ArtisanSearchForm/ArtisanSearchForm";
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { getGlobalParam } from './../../GlobalParams/Action';
import { getReferentiels } from './../../Referentiels/Action';
import { getArtisanBySearchCriteria, downloadArtisanCnssReport, downloadCertification } from './Action';
import Utils from '../../../utilities/Utils/Utils';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const Artisan = props => {
    const dispatch = useDispatch();
    const [searchCriteria, setSearchCriteria] = useState({})
    const artisans = useSelector(state => state.artisanReducer?.artisans);
    const artisanListMetadata = useSelector(state => state.artisanReducer?.artisanListMetadata);
    const niveau = useSelector(state => state.loginReducer?.profil?.niveau);
    const region = useSelector(state => state.loginReducer?.user?.region);
    const provinces = useSelector(state => state.loginReducer?.user?.userProvinces?.map(prov => prov.id));
    const province = useSelector(state => state.loginReducer?.user?.province);
    const loading = useSelector(state => state.artisanReducer?.loading);
    const profileCode = useSelector(state => state.loginReducer?.profil?.code);

    const paramsLoading = useSelector(state => state.globalParamReducer?.loading);
    const artisanStatutsLoading = useSelector(state => state.referentielReducer?.loading);

    const params = useSelector(state => state.globalParamReducer?.params);
    const artisanStatuts = useSelector(state => state.referentielReducer?.params?.artisanStatuts);
    //const activites = useSelector(state => {console.log('referentielReducer');console.log(state.referentielReducer); return state.referentielReducer?.params?.activites});
    const activites = useSelector(state => state.referentielReducer?.params?.activites);

    const location = useLocation();
    const [pagination, setPaginattion] = useState(Utils.DEFAULT_PAGINATION)
    const [sort, setSort] = useState(false)
    const [filters, setFilters] = useState({})
    const [searchMetadata, setSearchMeataData] = useState(Utils.PAGE_SEARCH_DEFAULT);

    useEffect(() => {
        dispatch(getGlobalParam())
        dispatch(getReferentiels());
        searchArtisan();
    }, [location]);

    const searchArtisan = (values, searchMetaCriteria = Utils.PAGE_SEARCH_DEFAULT) => {
        let searchcriteria = location.pathname.includes("artisans") || location.pathname.includes("search")
            ? values
            : { statut: Utils.getStatusToShow(profileCode) };
        if (searchcriteria && searchcriteria.province) {
            searchcriteria = { ...searchcriteria, province: [searchcriteria.province] }
        } else if (niveau === Utils.USER_LEVEL.LOCAL) {
            searchcriteria = { ...searchcriteria, province: provinces }
        }

        dispatch(getArtisanBySearchCriteria({ ...searchcriteria }, searchMetaCriteria));
    }

    const downloadCnssReport = () => {
        let params = "";
        if (searchCriteria?.type_artisan) {
            params += `type_artisan=${searchCriteria.type_artisan}`;
            if (searchCriteria.ville) {
                params += `&`;
            }
        }
        if (searchCriteria.province) {
            params += `province=${searchCriteria.province}`;
            if (searchCriteria.ville) {
                params += `&`;
            }
        }
        if (searchCriteria && searchCriteria.ville) {
            params += `ville=${searchCriteria.ville}`;
            if (searchCriteria.region) {
                params += `&`;
            }
        }
        if (searchCriteria && searchCriteria.region) {
            params += `region=${searchCriteria.region}`;
            if (searchCriteria.statut) {
                params += `&`;
            }
        }
        if (searchCriteria && searchCriteria.statut) {
            params += `statut=${searchCriteria.statut}`;
        }
        dispatch(downloadArtisanCnssReport(params));
    }

    const downloadCertificationPdf = (artisanId) => {
        dispatch(downloadCertification(artisanId));
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const searchMetaData = {
            page: pagination?.current,
            pageSize: pagination?.pageSize,
            withCount: true,
            sortField: sorter?.columnKey === 'dateDemandeInscription' ? 'date_demande_inscription' : sorter?.columnKey,
            sortDirection: sorter?.order,
        };
        setSearchMeataData(searchMetaData)
        setPaginattion(pagination);
        setSort(sorter);
        setFilters(filters);
        searchArtisan(searchCriteria, searchMetaData);
    };

    return (
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {paramsLoading || artisanStatutsLoading
                ? <SpinLoader />
                : <>
                    {location.pathname.includes("artisans") || location.pathname.includes("search")
                        ? <ArtisanSearchForm params={params} setSearchCriteria={setSearchCriteria} artisanStatuts={artisanStatuts} searchArtisan={searchArtisan} />
                        : ''
                    }
                    {loading
                        ? <SpinLoader />
                        : <div className="search-result-list">
                            <ArtisanList
                                artisans={artisans}
                                artisanListMetadata={artisanListMetadata}
                                activites={activites}
                                pagination={pagination}
                                sort={sort}
                                filters={filters}
                                handleTableChange={handleTableChange}
                                searchCriteria={searchCriteria}
                                downloadCnssReport={downloadCnssReport}
                                downloadCertificationPdf={downloadCertificationPdf}
                                isCentralValidator={niveau === Utils.USER_LEVEL.CENTRAL} />
                        </div>
                    }
                </>}
        </Space>
    );
}

export default Artisan;