import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import Utils from "../../../utilities/Utils/Utils";
import history from "../../../history";
import ReferentielVilleRegionsProvaince from "./components/ReferentielVilleRegionsProvaince/ReferentielVilleRegionsProvaince";
import ReferentielAnnexe from "./components/ReferentielAnnexe/ReferentielAnnexe";
import ReferentielCouvertureSocial from "./components/ReferentielCouvertureSocial/ReferentielCouvertureSocial";
import ReferentielPublicPrive from "./components/ReferentielPublicPrive/ReferentielPublicPrive";
import ReferentielActiviteDecret from "./components/ReferentielActiviteDecret/ReferentielActiviteDecret";
import ReferentielActivite from "./components/ReferentielActivite/ReferentielActivite";
import ReferentielDepartementFormation from "./components/ReferentielDepartementFormation/ReferentielDepartementFormation";
import ReferentielMotifDeRejet from "./components/ReferentielMotifDeRejet/ReferentielMotifDeRejet";
import ReferentielEntiteAdministrative from "./components/ReferentielEntiteAdministrative/ReferentielEntiteAdministrative";
import ReferentielCategoryDiplome from "./components/ReferentielCategoryDiplome/ReferentielCategoryDiplome";
import ReferentielDiplomeDistinct from "./components/ReferentielDiplomeDistinct/ReferentielDiplomeDistinct";
import ReferentielModeFormation from "./components/ReferentielModeFormation/ReferentielModeFormation";
import ReferentielDuree from "./components/ReferentielDuree/ReferentielDuree";
import ReferentielDiplome from "./components/ReferentielDiplome/ReferentielDiplome";
import ReferentielDiplomeDuree from "./components/ReferentielDiplomeDuree/ReferentielDiplomeDuree";
import ReferentielEtablissement from "./components/ReferentielEtablissement/ReferentielEtablissement";

const Referentiels = props => {
    const { refType } = useParams();
    const getReferentiel = () => {
        if (refType === Utils.REFERENTIEL_TYPES.CATEGORIE_DIPLOME) {
            return <ReferentielCategoryDiplome />
        } else if (refType === Utils.REFERENTIEL_TYPES.COUVERTURE_SOCIALE) {
            return <ReferentielCouvertureSocial />
        } else if (refType === Utils.REFERENTIEL_TYPES.DIPLOME) {
            return <ReferentielDiplome />
        } else if (refType === Utils.REFERENTIEL_TYPES.ESTABLISHMENT) {
            return <ReferentielEtablissement />
        } else if (refType === Utils.REFERENTIEL_TYPES.DUREE) {
            return <ReferentielDuree />
        } else if (refType === Utils.REFERENTIEL_TYPES.DIPLOME_DUREE) {
            return <ReferentielDiplomeDuree />
        } else if (refType === Utils.REFERENTIEL_TYPES.MODE_FORMATION) {
            return <ReferentielModeFormation />
        } else if (refType === Utils.REFERENTIEL_TYPES.DIPLOME_DISTINCT) {
            return <ReferentielDiplomeDistinct />
        } else if (refType === Utils.REFERENTIEL_TYPES.MOTIF_REJET) {
            return <ReferentielMotifDeRejet />
        } else if (refType === Utils.REFERENTIEL_TYPES.ENTITE_ADMINISTRATIVE) {
            return <ReferentielEntiteAdministrative />
        } else if (refType === Utils.REFERENTIEL_TYPES.PUBLIC_PRIVE) {
            return <ReferentielPublicPrive />
        } else if (refType === Utils.REFERENTIEL_TYPES.DEPARTEMENT_FORMATION) {
            return <ReferentielDepartementFormation />
        } else if (refType === Utils.REFERENTIEL_TYPES.ANNEXE) {
            return <ReferentielAnnexe />
        } else if ([Utils.REFERENTIEL_TYPES.REGIONS, Utils.REFERENTIEL_TYPES.PROVINCES, Utils.REFERENTIEL_TYPES.VILLES].includes(refType)) {
            return <ReferentielVilleRegionsProvaince />
        } else if ([Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET, Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET, Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET].includes(refType)) {
            return <ReferentielActiviteDecret />
        } else if ([Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE, Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE, Utils.REFERENTIEL_TYPES.ACTIVITE].includes(refType)) {
            return <ReferentielActivite />
        } else {
            history.push("/app/agent/home");
        }
    }

    return (getReferentiel());
}

export default Referentiels;