import React, { useState } from 'react';
import {
    Row, Col, Button,
    Space,
    Card,
} from "antd";
import { DownloadOutlined } from '@ant-design/icons';

const Documents = props => {


    const { documents, downloadFile, artisanType, isCommiteFile } = props;
    const [file, setFile] = useState();
    const [fileTye, setFileType] = useState('');
    const [fileFormat, setFileFormat] = useState();

    const handleViewDocument = (id, fileType) => {
        setFileFormat(fileType)
        downloadFile(id).then(response => {
            const fileType = response.value.headers.x_file_extension;
            setFileType(fileType);
            const base64 = response.value.data;
            var blob = fileType === 'pdf' ? new Blob([base64], { type: 'application/pdf' }) : base64;
            var blobURL = URL.createObjectURL(blob);
            setFile(blobURL);
            if (fileType === 'pdf') {
                window.open(blobURL);
            }
        });
    }


    return (
        <Card  >
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                <Space direction="vertical" size="middle" className="w-100">
                    <Row>
                        <Col lg={6} md={24} sm={24} xs={24} >
                            {documents.map(doc => {
                                if(!(doc.document_type_id == '1500' && (artisanType == 'COOPERATIVE_ARTISANAT' || artisanType == 'ENTREPRISE_ARTISANAT'))) 
                                    return (
                                        <Col span={8}>
                                            <Button shape="round" type="link" icon={<DownloadOutlined />} size="large" onClick={() => handleViewDocument(doc.id, doc.file_name.split('.')[1])} block>
                                                { isCommiteFile ? doc.original_file_name : doc.type_document.description}
                                            </Button>
                                        </Col>)
                            })}
                        </Col>
                        <Col lg={18} md={24} sm={24} xs={24} style={{ border: '1' }}>
                            <Card >
                                {file && <>
                                    {fileTye === 'pdf' ?
                                        <h4 style={{ textAlign: 'center' }}>Les fichiers Pdf sont afficher dans une nouvelle ongle</h4> :
                                        <img id="image" src={file} width="100%" />}
                                </>}
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Row >
        </Card>
    );

};


export default Documents;