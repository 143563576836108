import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getRegions = () => ({
  type: types.GET_REGIONS,
  payload: Api.referentiels.regions.getAll(),
});
export const getEntiteAdministrative = () => ({
  type: types.GET_ENTITE_ADMINISTRATIVE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ENTITE_ADMINISTRATIVE),
});
export const updateEntiteAdministrative = (id, paylaod) => ({
  type: types.UPDATE_ENTITE_ADMINISTRATIVE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addEntiteAdministrative = (paylaod) => {
  return {
    type: types.ADD_ENTITE_ADMINISTRATIVE,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};