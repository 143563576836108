import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getActiviteBranche = () => ({
  type: types.GET_ACTIVITE_BRANCHE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE),
});
export const updateActiviteBranche = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE_BRANCHE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActiviteBranche = (paylaod) => ({
  type: types.ADD_ACTIVITE_BRANCHE,
  payload: Api.referentiels.referentiel.add(paylaod),
});

export const getActiviteFiliere = () => ({
  type: types.GET_ACTIVITE_FILIERE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE),
});
export const updateActiviteFiliere = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE_FILIERE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActiviteFiliere = (paylaod) => ({
  type: types.ADD_ACTIVITE_FILIERE,
  payload: Api.referentiels.referentiel.add(paylaod),
});

export const getActivite = () => ({
  type: types.GET_ACTIVITE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE),
});
export const updateActivite = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActivite = (paylaod) => ({
  type: types.ADD_ACTIVITE,
  payload: Api.referentiels.referentiel.add(paylaod),
});
