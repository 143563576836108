import React, { useEffect } from 'react';
import { Form, Row, Col, Button, Divider } from 'antd';
import { getGlobalParam } from '../../../../GlobalParams/Action';
import { getUsersBySearchCriteria } from '../../Action';
import { SeletWithDoubleLabel, InputWithDoubleLabel, InputEmailWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import { useDispatch, useSelector } from 'react-redux';
import './UserSearchForm.less';
import { useTranslation } from 'react-i18next';
import history from '../../../../../history';
import Utils from '../../../../../utilities/Utils/Utils';

const UserSearchForm = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const params = useSelector(state => state.globalParamReducer?.params);

    const [provinces, setProvinces] = React.useState(params.provinces);
    const [regions, setRegions] = React.useState(params.regions);



    const onChangeRegion = e => {
        if (e) {
            setProvinces(props.params.provinces.filter(province => province.regionId === e));
        } else {
            setProvinces(props.params.provinces)
        }

        setProvinces(props.params.provinces.filter(province => province.regionId === e));

        form.setFieldsValue({ province: '' })
    };

    const onChangeProvince = e => {

        if (e) {
            var province = props.params.provinces.filter(province => province.id === e);

            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }
        } else {
            var region = form.getFieldValue('region')
        }
    };

    useEffect(() => {
        dispatch(getGlobalParam())
    }, [])

    const onFinish = (values) => {
        dispatch(getUsersBySearchCriteria(values));
    };


    return (<>
        <Divider orientation="left">{t('agent.user-search-form.title')}</Divider>
        <Row justify="end" align="middle" style={{ paddingBottom: '15px' }}>
            <Button shape='round' type="primary" htmlType="submit" onClick={() => history.push('/app/agent/users/add')}>
                {t('agent.user-search-form.add-user')}
            </Button>
        </Row>
        <Form
            shape="round"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onFinish}
            preserve={true}
        >
            <Row gutter={24}>

                <Col span={12}>
                    <InputWithDoubleLabel type={Utils.TEXT_TYPE.NAME_WITH_SPACE} required={false} name="first_name" label={t('agent.user-search-form.form.nom.label')} placeholder={t('agent.user-search-form.form.nom.placeholder')} errorMessage={t('agent.user-search-form.form.nom.error-message')} />
                </Col>

                <Col span={12}>
                    <InputWithDoubleLabel type={Utils.TEXT_TYPE.NAME_WITH_SPACE} required={false} name="last_name" label={t('agent.user-search-form.form.prenom.label')} placeholder={t('agent.user-search-form.form.prenom.placeholder')} errorMessage={t('agent.user-search-form.form.prenom.error-message')} />
                </Col>

                <Col span={12}>
                    <InputEmailWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} required={false} name="email" label={t('agent.user-search-form.form.email.label')} placeholder={t('agent.user-search-form.form.email.placeholder')} errorMessage={t('agent.user-search-form.form.email.error-message')} />
                </Col>

                <Col span={12}>
                    <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} required={false} name="login" label={t('agent.user-search-form.form.login.label')} placeholder={t('agent.user-search-form.form.login.placeholder')} errorMessage={t('agent.user-search-form.form.login.error-message')} />
                </Col>

                <Col span={12}>
                    <SeletWithDoubleLabel required={false} name="region" label={t('agent.user-search-form.form.region.label')} placeholder={t('agent.user-search-form.form.ville.placeholder')} options={regions ?? []} onChangeAction={onChangeRegion} errorMessage={t('agent.user-search-form.form.region.error-message')} />
                </Col>
                <Col span={12}>
                    <SeletWithDoubleLabel required={false} name="province" label={t('agent.user-search-form.form.province.label')} placeholder={t('agent.user-search-form.form.ville.placeholder')} options={provinces ?? []} onChangeAction={onChangeProvince} errorMessage={t('agent.user-search-form.form.province.error-message')} />
                </Col>

            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        shape='round'
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        {t('agent.user-search-form.form.reset.label')}
                    </Button>
                    <Button shape='round' type="primary" htmlType="submit">
                        {t('agent.user-search-form.form.submit.label')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </>
    );
};

export default UserSearchForm;
