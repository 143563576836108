import { Row, Space } from "antd";
import React, { useEffect, useState } from "react"
import UserList from "./components/UserList/UserList";
import UserSearchForm from "./components/UserSearchForm/UserSearchForm";
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { getUsersBySearchCriteria, deleteUserById, deactivateUserById } from './Action';
import { useDispatch, useSelector } from "react-redux";
import { getGlobalParam } from "../../GlobalParams/Action";

const Users = props => {
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const users = useSelector(state => state.usersReducer?.users);
    const loading = useSelector(state => state.usersReducer?.loading);
    const error = useSelector(state => state.usersReducer?.error);
    const userId = useSelector(state => state.loginReducer?.profil?.id);
    const params = useSelector(state => state.globalParamReducer?.params);

    useEffect(() => {
        dispatch(getGlobalParam()).then(() => {
            dispatch(getUsersBySearchCriteria({}));
        })
    }, []);


    const deleteUser = userId => {
        dispatch(deleteUserById(userId))
            .then((res) => {
                setSuccessMessage("DELETE_USER_FULFILLED");
                dispatch(getUsersBySearchCriteria({}));
            }
            ).catch(err => {
                setErrorMessage("DELETE_USER_REJECTED")
            });
    }
    const deactivateUser = userId => {
        dispatch(deactivateUserById(userId))
            .then((res) => {
                setSuccessMessage("DEACTIVATE_USER_FULFILLED");
                dispatch(getUsersBySearchCriteria({}));
            }
            ).catch(err => {
                setErrorMessage("DEACTIVATE_USER_REJECTED")
            });
    }


    return (<>
        <Row>
            <Space size="large" direction="vertical" style={{ width: '100%' }}  >
                <UserSearchForm params={params} />
                {loading ? <SpinLoader /> :
                    <div className="search-result-list">
                        <UserList users={users.filter(user => user.profile !== 'Profil Artisan' && user.key !== userId)} deactivateUser={deactivateUser} deleteUser={deleteUser} error={errorMessage} success={successMessage} />
                    </div>}
            </Space>
        </Row></>);
}

export default Users;