import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, addProfil } from '../../Action'
import { Button, Col, Row, Space, Alert, Divider, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import { useParams } from 'react-router';


const ProfileDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id, action } = useParams();
    const [form] = Form.useForm();

    const error = useSelector(state => state.habilitationReducer?.error);
    const success = useSelector(state => state.habilitationReducer?.success);


    useEffect(() => {
        dispatch(clearMessages());
    }, []);

    const onFinish = (values) => {
        dispatch(addProfil(values));
    };
    const types = [
        { 'id': 'CHARGE_RNA', 'name': { 'FR': 'CHARGE RNA' } },
        { 'id': 'COMMISSION_RNA', 'name': { 'FR': 'COMMISSION RNA' } },
        { 'id': 'RESPONSABLE_RNA', 'name': { 'FR': 'RESPONSABLE RNA' } },
        { 'id': 'VALIDATEUR_CENTRAL_1', 'name': { 'FR': 'VALIDATEUR CENTRAL 1' } },
        { 'id': 'VALIDATEUR_CENTRAL_2', 'name': { 'FR': 'VALIDATEUR CENTRAL 2' } },
        { 'id': 'ADMIN', 'name': { 'FR': 'ADMIN' } }
    ]

    const niveaux = [
        { 'id': 'LOCAL', 'name': { 'FR': 'LOCAL' } },
        { 'id': 'CENTRAL', 'name': { 'FR': 'CENTRAL' } }
    ]

    return (
        <>
            <Space direction="vertical" size="middle" className="w-100">
                <Divider orientation="left">{t('agent.habilitation.form-profile.title')}</Divider>

                {error &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                        <Col span={24}>
                            <Alert
                                style={{ textAlign: 'center', width: '100%' }}
                                message={error}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {success &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                        <Col span={24}>
                            <Alert
                                style={{ textAlign: 'center', width: '100%' }}
                                message={success}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }


                <Card >
                    <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
                        <Space direction="vertical" size="middle" className="w-100">
                            <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                                <Form form={form}
                                    name="create-user"
                                    style={{ width: '100%' }}
                                    size='small'
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 14 }}
                                    onFinish={onFinish}
                                    layout="horizontal"
                                    scrollToFirstError
                                >
                                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Col span={11}>
                                            <InputWithDoubleLabel type={'alphanumeric'} name="code" label={t('agent.habilitation.form-profile.form.code.label')} placeholder={t('agent.habilitation.form-profile.form.code.placeholder')} errorMessage={t('agent.habilitation.form-profile.form.code.error-message')} />
                                        </Col>
                                        <Divider type="vertical" />
                                        <Col span={11}>
                                            <InputWithDoubleLabel type={'alphabeticWithSpace'} name="description" label={t('agent.habilitation.form-profile.form.description.label')} placeholder={t('agent.habilitation.form-profile.form.description.placeholder')} errorMessage={t('agent.habilitation.form-profile.form.description.error-message')} />
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Col span={11}>
                                            <SeletWithDoubleLabel required={true} name="niveau" label={t('agent.habilitation.form-profile.form.niveau.label')} placeholder={t('agent.habilitation.form-profile.form.niveau.placeholder')} errorMessage={t('agent.habilitation.form-profile.form.niveau.error-message')} options={niveaux} />
                                        </Col>
                                        <Divider type="vertical" />
                                        <Col span={11}>
                                            <SeletWithDoubleLabel required={true} name="type" label={t('agent.habilitation.form-profile.form.type.label')} placeholder={t('agent.habilitation.form-profile.form.type.placeholder')} errorMessage={t('agent.habilitation.form-profile.form.type.error-message')} options={types} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            <Button shape='round' type="primary" htmlType="submit" size='large' >
                                                {t('agent.habilitation.form-profile.form.submit')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Space>
                    </Row>

                </Card>

            </Space>

        </>
    )
}

export default ProfileDetail;
