import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getActiviteBrancheDecrets = () => ({
  type: types.GET_ACTIVITE_BRANCHE_DECRET,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE_DECRET),
});
export const updateActiviteBrancheDecret = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE_BRANCHE_DECRET,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActiviteBrancheDecret = (paylaod) => ({
  type: types.ADD_ACTIVITE_BRANCHE_DECRET,
  payload: Api.referentiels.referentiel.add(paylaod),
});

export const getActiviteFiliereDecrets = () => ({
  type: types.GET_ACTIVITE_FILIERE_DECRET,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE_DECRET),
});
export const updateActiviteFiliereDecret = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE_FILIERE_DECRET,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActiviteFiliereDecret = (paylaod) => ({
  type: types.ADD_ACTIVITE_FILIERE_DECRET,
  payload: Api.referentiels.referentiel.add(paylaod),
});

export const getActiviteDecret = () => ({
  type: types.GET_ACTIVITE_DECRET,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_DECRET),
});
export const updateActiviteDecret = (id, paylaod) => ({
  type: types.UPDATE_ACTIVITE_DECRET,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addActiviteDecret = (paylaod) => ({
  type: types.ADD_ACTIVITE_DECRET,
  payload: Api.referentiels.referentiel.add(paylaod),
});
