import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getPublicPrive = () => ({
  type: types.GET_PUBLIC_PRIVE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.PUBLIC_PRIVE),
});
export const getModeFormation = () => ({
  type: types.GET_MODE_FORMATION,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.MODE_FORMATION),
});
export const getDiplomeDistinct = () => ({
  type: types.GET_DIPLOME_DISTINCT,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME_DISTINCT),
});
export const getCategoryDiplome = () => ({
  type: types.GET_CATEGORIE_DIPLOME,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.CATEGORIE_DIPLOME),
});

export const getDepartementFormation = () => ({
  type: types.GET_DEPARTEMENT_FORMATION,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DEPARTEMENT_FORMATION),
});

export const getEtablissement = () => ({
  type: types.GET_ETABLISSMENT,
  payload: Api.referentiels.etablissements.getAll(),
});

export const getDiplome = () => ({
  type: types.GET_DIPLOME,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME),
});
export const updateDiplome = (id, paylaod) => ({
  type: types.UPDATE_DIPLOME,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addDiplome = (paylaod) => {
  return {
    type: types.ADD_DIPLOME,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};