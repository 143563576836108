import React, { useEffect } from "react"
import { Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { getArtisanCountReport } from './Action';
import { getGlobalParam } from '../../GlobalParams/Action';
import { getReferentiels } from '../../Referentiels/Action';
import { useDispatch, useSelector } from "react-redux";
import ReportSearchForm from "./components/ReportSearchForm/ReportSearchForm";
import SpinLoader from "../../../component/SpinLoader/SpinLaoder";
import ReportCards from "./components/ReportCards/ReportCards";
import Utils from "../../../utilities/Utils/Utils";


const Report = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { USER_LEVEL } = Utils;

    const report = useSelector(state => state.reportReducer?.report);
    const loading = useSelector(state => state.reportReducer?.loading);

    const paramsLoading = useSelector(state => state.globalParamReducer?.loading);
    const artisanStatutsLoading = useSelector(state => state.referentielReducer?.loading);

    const params = useSelector(state => state.globalParamReducer?.params);
    const artisanStatuts = useSelector(state => state.referentielReducer?.params?.artisanStatuts);

    const regionId = useSelector(state => state.loginReducer?.user?.region?.id);
    const niveau = useSelector(state => state.loginReducer?.profil?.niveau);


    useEffect(() => {
        dispatch(getGlobalParam())
        dispatch(getReferentiels());
        search({})
    }, []);

    const search = values => {
        let searchCriteria = values && values.statut
            ? { ...values, statut: [values.statut] }
            : values;

        if (niveau === USER_LEVEL.LOCAL) {
            searchCriteria = {
                ...searchCriteria,
                region: regionId
            }
        }

        dispatch(getArtisanCountReport(searchCriteria));
    }

    return (<>
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {loading || paramsLoading || artisanStatutsLoading ? <SpinLoader /> :
                <>
                    <ReportSearchForm search={search} params={params} artisanStatuts={artisanStatuts} />
                    <ReportCards report={report} />
                </>
            }
        </Space>
    </>);

}

export default Report;


