import React, { useRef, useState } from "react";
import { Input, Button, Space, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import history from '../../../../../history';
import { SearchOutlined, EyeOutlined, ClockCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import TableWithExport from "../../../../../component/Table/TableWithExport";
import moment from "moment";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const ArtisanList = props => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const searchInput = useRef(null);
    const {  artisans: data, pagination: _pagination, sort: _sorter, artisanListMetadata, downloadCnssReport, downloadCertificationPdf, isCentralValidator, handleTableChange } = props;
    const { t } = useTranslation();
    const localtion = useLocation();
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t('agent.artisan-list.filterDropdown.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                            fontSize: '12px',
                            padding: 0
                        }}
                    >
                        {t('agent.artisan-list.filterDropdown.search')}
                    </Button>
                    <Button
                        shape="round"
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                            fontSize: '12px'
                        }}
                    >
                        {t('agent.artisan-list.filterDropdown.reset')}
                    </Button>
                    <Button
                        shape="round"
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        style={{
                            fontSize: '12px'
                        }}
                    >
                        {t('agent.artisan-list.filterDropdown.filter')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const getActivite = record => {
        var activites  = props.activites.filter(e => e.id == record.activite)
        
        var activite_str = (activites.length > 0)?activites[0].name.FR: '';
        return activite_str;
        //return (<span> {activite_str}</span>)

    }

    data.forEach(row => {
        row.activite = getActivite(row)
    })

    const columns = [
        {
            title: t('agent.artisan-list.columns.action'),
            dataIndex: 'action',
            key: Math.random,
            render: (text, record, index) => (
                <Space size="middle">
                    <a onClick={() => handleDetails(record.key)}><EyeOutlined /> </a>                    
                    {record.statut == 'VALIDE' ?(<a onClick={() => downloadCertificationPdf(record.key)}><FilePdfOutlined /> </a>): null}
                </Space>
            ),
        },
        {
            title: t('agent.artisan-list.columns.timeStatut'),
            dataIndex: 'timeStatut',
            key: 'timeStatut',
            render: (text, record, index) => (
                <div>
                    {getDelaiStatus(record)}
                </div>
            )
        },
        {
            title: t('agent.artisan-list.columns.dateInitiation'),
            dataIndex: 'dateDemandeInscription',
            key: 'dateDemandeInscription',
            sorter: (a, b) => moment(a.dateDemandeInscription).unix() - moment(b.dateDemandeInscription).unix(),
            sortOrder: _sorter.order

        },
        {
            title: t('agent.artisan-list.columns.dateDerniereSoumission'),
            dataIndex: 'dateDerniereSoumission',
            key: 'dateDerniereSoumission',
            sorter: (a, b) => moment(a.dateDerniereSoumission).unix() - moment(b.dateDerniereSoumission).unix(),
            sortOrder: _sorter.order

        },{
            title: t('agent.artisan-list.columns.dateDerniereValidation'),
            dataIndex: 'dateDerniereValidation',
            key: 'dateDerniereValidation',
            sorter: (a, b) => moment(a.dateDerniereValidation).unix() - moment(b.dateDerniereValidation).unix(),
            sortOrder: _sorter.order

        },{
            title: t('agent.artisan-list.columns.dateEmissionNIA'),
            dataIndex: 'datePremiereValidation',
            key: 'datePremiereValidation',
            sorter: (a, b) => moment(a.datePremiereValidation).unix() - moment(b.datePremiereValidation).unix(),
            sortOrder: _sorter.order

        },
        {
            title: t('agent.artisan-list.columns.statut'),
            dataIndex: 'statut',
            key: 'statut',
            render: (text, record, index) => (
                <span>{t(record.statut)}</span>
            )
        },
        {
            title: t('agent.artisan-list.columns.artisan-type'),
            dataIndex: 'artisanTypeFR',
            key: 'artisanTypeFR',
            ...getColumnSearchProps('artisanTypeFR'),
        },
        {
            title: t('agent.artisan-list.columns.nom'),
            dataIndex: 'nom',
            key: 'nom',
            ...getColumnSearchProps('nom'),
        },
        {
            title: t('agent.artisan-list.columns.prenom'),
            dataIndex: 'prenom',
            key: 'prenom',
            ...getColumnSearchProps('prenom'),
        },
        {
            title: t('agent.artisan-list.columns.cnie'),
            dataIndex: 'cnie',
            key: 'cnie',
            ...getColumnSearchProps('cnie'),
        },
        {
            title: t('agent.artisan-list.columns.telephone'),
            dataIndex: 'telephone',
            key: 'telephone',
            ...getColumnSearchProps('telephone'),
        },
        {
            title: t('agent.artisan-list.columns.nom-cooperative'),
            dataIndex: 'nom_cooperative',
            key: 'nom_cooperative',
            ...getColumnSearchProps('nom_cooperative'),
        },
        {
            title: t('agent.artisan-list.columns.nom-entreprise'),
            dataIndex: 'nom_entreprise',
            key: 'nom_entreprise',
            ...getColumnSearchProps('nom_entreprise'),
        },{
            title: t('agent.artisan-list.columns.activite'),
            dataIndex: 'activite',
            key: 'activite',
            width: 300,
            //sorter: (a, b) => moment(a.datePremiereValidation).unix() - moment(b.datePremiereValidation).unix(),
            /*render: (text, record, index) => (
                <div>
                    {getActivite(record)}
                </div>
            )*/

        },
        {
            title: t('agent.artisan-list.columns.num-identite'),
            dataIndex: 'num_identite',
            key: 'num_identite',
            ...getColumnSearchProps('num_identite'),
        },
        {
            title: t('agent.artisan-list.columns.date-radiation'),
            dataIndex: 'date_radiation',
            key: 'date_radiation',
            ...getColumnSearchProps('date_radiation'),
        },
        
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const handleDetails = (record) => {
        history.push('/app/agent/search/' + record + '/details', { from: location.pathname });
    }

    const getDelaiStatus = record => {
        console.log('props.activites')
        console.log(props.activites)
        //const given = moment(record.dateDemandeInscription, "DD-MM-YYYYY");
        const date_derniere_soumission = moment(record.dateDerniereSoumission, "DD-MM-YYYYY");
        const current = moment().startOf('day');//add .add(20, 'd') for tests
        var numberOfDay = moment.duration(current.diff(date_derniere_soumission)).asDays();
        if(record.dateDerniereValidation){ // déjà validé au mois une fois
            const derniereValidation = moment(record.dateDerniereValidation, "DD-MM-YYYYY");
            numberOfDay = moment.duration(derniereValidation.diff(date_derniere_soumission)).asDays();
        }

        if (numberOfDay < 15) {
            return (<span> <Tooltip title="Délai < 15jours" >
                <ClockCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
            </span>)
        } else if (numberOfDay > 15 && numberOfDay < 30) {
            return (<span> <Tooltip title="Délai entre 15 et 30 jours" >
                <ClockCircleOutlined style={{ color: 'orange' }} />
            </Tooltip></span>)
        } else {
            return (<span> <Tooltip title="Délai > 30 jours" >
                <ClockCircleOutlined style={{ color: 'red' }} />
            </Tooltip></span>)
        }

    }


    const handleChanged = (pagination, filters, sorter) => {
        if (isDeepEmpty(filters)) {
            handleTableChange(pagination, filters, sorter);
        };
    };


    const isDeepEmpty = (obj) => {
        if (Object.keys(obj).length === 0) {
            return true;
        };
        let numberOfFilterColumn = 0;
        Object.values(columns).filter(al => {
            const filter = Object.keys(al).filter(o => {
                return o === "onFilter"
            }).length;
            numberOfFilterColumn = numberOfFilterColumn + filter;
            return true;
        })
        const numberOfNull = Object.values(obj).filter(ele => ele === null).length;
        return numberOfNull === numberOfFilterColumn ? true : false;
    }


    let locale = {
        emptyText: 'Pas de données',
    };

    return <>
        <TableWithExport
            data={data}
            onChange={handleChanged}
            pagination={_pagination}
            total={artisanListMetadata?.total}
            columns={columns}
            downloadCnssReport={downloadCnssReport}
            showCnssExport={isCentralValidator}
            title={location.pathname.includes("artisans") ? t('agent.artisan-list.divider.search') : t('agent.artisan-list.divider.home')} />
    </>;
}

export default ArtisanList;