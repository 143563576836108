import types from './ActionTypes';
import Api from '../../../utilities/services/Api';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getAllSms = () => ({
  type: types.GET_ALL_SMS,
  payload: Api.sms.getAll()
});

export const updateSms = (code,payload) => ({
  type: types.UPDATE_SMS,
  payload: Api.sms.updateLabel(code,payload)
});
