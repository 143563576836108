import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getCouvertureSociale = () => ({
  type: types.GET_COUVERTURE_SOCIALE,
  payload: Api.referentiels.couvertureSociale.getAll(),
});
export const updateCouvertureSociale = (id, paylaod) => ({
  type: types.UPDATE_COUVERTURE_SOCIALE,
  payload: Api.referentiels.couvertureSociale.update(id, paylaod),
});
export const addCouvertureSociale = (paylaod) => {
  return {
    type: types.ADD_COUVERTURE_SOCIALE,
    payload: Api.referentiels.couvertureSociale.add(paylaod),
  }
};