import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getCategoryDiplome = () => ({
  type: types.GET_CATEGORIE_DIPLOME,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.CATEGORIE_DIPLOME),
});
export const updateCategoryDiplome = (id, paylaod) => ({
  type: types.UPDATE_CATEGORIE_DIPLOME,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addCategoryDiplome = (paylaod) => {
  return {
    type: types.ADD_CATEGORIE_DIPLOME,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};