import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Divider } from 'antd';
import { InputCNIEWithDoubleLabel, InputCNIEWithTypeIdentity, InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import { useSelector } from 'react-redux';
import './ArtisanSearchForm.less';
import Utils from '../../../../../utilities/Utils/Utils';
import { useTranslation } from 'react-i18next';

const ArtisanSearchForm = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [identity, setIdentity] = useState(Utils.TYPE_IDENTITIES.CNIE);
    const [searchValues, setSearchValues] = useState({ type_identite: Utils.TYPE_IDENTITIES.CNIE });
    const { USER_LEVEL } = Utils;
    const { setSearchCriteria } = props;
    const niveau = useSelector(state => state.loginReducer?.profil?.niveau);
    const regionId = useSelector(state => state.loginReducer?.user?.region?.id);
    const userProvinces = useSelector(state => state.loginReducer?.user?.userProvinces?.map(prov => prov.id));
    const provinceId = useSelector(state => state.loginReducer?.user?.province?.id);

    const [villes, setVilles] = React.useState(niveau === USER_LEVEL.LOCAL ? props.params.villes?.filter(ville => ville.regionId === regionId) : props.params.villes);

    const [provinces, setProvinces] = React.useState(niveau === USER_LEVEL.LOCAL ? props.params.provinces.filter(province => userProvinces?.includes(province.id)) : props.params.provinces);
    const [regions, setRegions] = React.useState(props.params.regions);

    useEffect(() => {
        setSearchValues({ ...searchValues, type_identite: Utils.TYPE_IDENTITIES.CNIE })
    }, []);

    const onChangeRegion = e => {
        if (e) {
            setProvinces(props.params.provinces.filter(province => province.regionId === e));
            setVilles(props.params.villes.filter(ville => ville.regionId === e));
        } else {
            setProvinces(props.params.provinces)
            setVilles(props.params.villes)
        }

        form.setFieldsValue({ province: '' })
        form.setFieldsValue({ ville: '' })
    };

    const onChangeProvince = e => {
        if (e) {
            setVilles(props.params.villes.filter(ville => ville.provinceId === e));
            var province = props.params.provinces.filter(province => province.id === e);

            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }
        } else {
            var region = form.getFieldValue('region')
            setVilles(props.params.villes.filter(ville => ville.regionId === region));
        }

        form.setFieldsValue({ ville: '' })
    };

    const onChangeVille = e => {
        var ville = props.params.villes.filter(ville => ville.id === e);

        if (ville && ville.length !== 0) {
            form.setFieldsValue({ region: ville[0].regionId })
            form.setFieldsValue({ province: ville[0].provinceId })

            setProvinces(props.params.provinces.filter(p => p.regionId === ville[0].regionId));
            setVilles(props.params.villes.filter(v => v.provinceId === ville[0].provinceId))
        }
    }

    const onFinish = (values) => {

        if (values?.region === "") {
            values.region = undefined;
        }
        if (values?.province === "") {
            values.province = undefined;
        }
        if (values?.ville === "") {
            values.ville = undefined;
        }
        setSearchValues(values)
        let searchcriteria = values && values.statut
            ? { ...values, statut: [values.statut] }
            : values;
        setSearchCriteria(searchcriteria);
        props.searchArtisan(searchcriteria);
    };
    const onChangeIdentity = e => {
        setIdentity(e)
    }

    return (<>
        <Divider orientation="left">{t('agent.artisan-search-form.divider')}</Divider>
        <Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onFinish}
            preserve={true}
            initialValues={searchValues}
        >
            <Row gutter={24}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel required={false} name="type_artisan" label={t('agent.artisan-search-form.form.type-artisan.label')} placeholder={t('agent.artisan-search-form.form.type-artisan.placeholder')} errorMessage={t('agent.artisan-search-form.form.type-artisan.error-message')} options={props.params.artisanTypes ?? []} />
                </Col>
                {niveau === USER_LEVEL.CENTRAL ?
                    <>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <SeletWithDoubleLabel required={false} name="region" label={t('agent.artisan-search-form.form.region.label')} placeholder={t('agent.artisan-search-form.form.ville.placeholder')} options={regions ?? []} onChangeAction={onChangeRegion} errorMessage={t('agent.artisan-search-form.form.region.error-message')} />
                        </Col>
                    </> : ''}

                <Col lg={12} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel required={false} name="province" label={t('agent.artisan-search-form.form.province.label')} placeholder={t('agent.artisan-search-form.form.ville.placeholder')} options={provinces ?? []} onChangeAction={onChangeProvince} errorMessage={t('agent.artisan-search-form.form.province.error-message')} />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel required={false} type="text" name="ville" label={t('agent.artisan-search-form.form.ville.label')} placeholder={t('agent.artisan-search-form.form.ville.placeholder')} options={villes ?? []} onChangeAction={onChangeVille} errorMessage={t('agent.artisan-search-form.form.ville.error-message')} />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel required={false} name="statut" label={t('agent.artisan-search-form.form.statut.label')} placeholder={t('agent.artisan-search-form.form.statut.placeholder')} errorMessage={t('agent.artisan-search-form.form.statut.error-message')} options={props.artisanStatuts ?? []} />
                </Col>

                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputCNIEWithTypeIdentity
                        required={false}
                        options={Utils.identites}
                        cnieName="cnie"
                        typeIdentityName="type_identite"
                        typeIdentitValue={identity}
                        label={t(`component.personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)}
                        typeIdentityErrorMessage={t('component.personal-data.form.typeIdentite.error-message')}
                        errorMessage={t(`component.personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.error-message`)}
                        onChangeAction={onChangeIdentity} />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel required={false} name="nom" type={Utils.TEXT_TYPE.NAME_WITH_SPACE} label={t('agent.artisan-search-form.form.nom.label')} placeholder={t('agent.artisan-search-form.form.nom.placeholder')} errorMessage={t('agent.artisan-search-form.form.nom.error-message')} />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel required={false} name="prenom" type={Utils.TEXT_TYPE.NAME_WITH_SPACE} label={t('agent.artisan-search-form.form.prenom.label')} placeholder={t('agent.artisan-search-form.form.prenom.placeholder')} errorMessage={t('agent.artisan-search-form.form.prenom.error-message')} />
                </Col>

            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        shape="round"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                            form.resetFields();
                            setSearchCriteria({});
                            setSearchValues({});
                        }}
                    >
                        {t('agent.artisan-search-form.form.reset.label')}
                    </Button>
                    <Button shape="round" type="primary" htmlType="submit">
                        {t('agent.artisan-search-form.form.submit.label')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </>
    );
};

export default ArtisanSearchForm;
