import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetMessages, createUser, updateUser, getUserById } from '../../Action'
import { getGlobalParam } from '../../../../GlobalParams/Action';
import { getProfiles } from '../../../../Profiles/Action';
import { Button, Col, Row, Space, Alert, Divider, Card } from 'antd';
import SpinLoader from '../../../../../component/SpinLoader/SpinLaoder';
import history from '../../../../../history';
import { useTranslation } from 'react-i18next';
import { InputEmailWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel, SeletMultipleWithDoubleLabel, InputPhoneNumberWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import { useParams } from 'react-router';
import Utils from '../../../../../utilities/Utils/Utils';


const UserDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id, action } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const user = useSelector(state => state.usersReducer?.user);
    const error = useSelector(state => state.usersReducer?.error);
    const success = useSelector(state => state.usersReducer?.success);

    const params = useSelector(state => state.globalParamReducer?.params);
    const profiles = useSelector(state => state.profilesReducer?.profiles?.filter(profile => profile.code !== "ARTISAN"));

    const [provinces, setPronvices] = useState(params.provinces);

    const [provinceVisible, setProvinceVisible] = useState("");

    useEffect(() => {
        dispatch(resetMessages());

        if (action && id && (action === 'details' || action === 'update')) {
            dispatch(getUserById(id))
                .then((res) => dispatch(getGlobalParam()))
                .then((res) => dispatch(getProfiles()))
                .then((res) => setLoading(false))
                .catch((res) => setLoading(false));

        } else if (action || id) {
            history.push('app/agent/home')
        } else {

            dispatch(getGlobalParam())
                .then((res) => dispatch(getProfiles())
                    .then((res) => setLoading(false)))
                .catch((res) => setLoading(false))
        }


    }, []);

    const onFinish = (values) => {
        action === 'update'
            ? dispatch(updateUser(id, {...values, telephone: Utils.mapNumberToPhoneNumber(values.telephone)} ))
            : dispatch(createUser({...values, telephone: Utils.mapNumberToPhoneNumber(values.telephone)}));
    };

    const getInitData = () => {
        console.log(user)
        return (action && id && user && user.login)
            ? {
                ...user,
                first_name: user.nom,
                last_name: user.prenom,
                login: user.login,
                region: user?.region?.id,
                province_ids: user.province?.map(p => p.id),
                telephone: Utils.mapPhoneNumberToNumber(user.telephone),
                operateur: user.operateur
            }
            : {};
    }

    const isConsultation = () => {
        return action && action === 'details';
    }

    const isUpdate = () => {
        return action && action === 'update';
    }

    const onChangeProfil = (profilId) => {
        if (profilId) {
            const profil = profiles.filter(p => p.id === profilId);
            setProvinceVisible(profil[0].niveau === Utils.USER_LEVEL.LOCAL)
        }
    }

    const onChangeRegion = (regionId) => {
        if (regionId) {
            setPronvices(params.provinces.filter(p => p.regionId === regionId));
            form.setFieldsValue({ province_ids: [] })
        } else {
            setPronvices(params.provinces);
            form.setFieldsValue({ province_ids: [] })
        }
    }

    const onChangeProvince = (provinceId) => {
        const province = provinces.filter(p => p.id === provinceId[0]);
        if (province && province.length > 0) {
            form.setFieldsValue({ region: province[0].regionId })
            const filtredProvinces = provinces.filter(p => p.regionId === province[0].regionId);
            setPronvices(filtredProvinces);
        }
    }

    const isProvinceVisible = () => {
        if (isConsultation()) {
            return user?.niveau === Utils.USER_LEVEL.LOCAL;
        } else if (isUpdate()) {
            if (provinceVisible === "")
                return user?.niveau === Utils.USER_LEVEL.LOCAL;
            else return provinceVisible;
        } else {
            return provinceVisible;
        }
    }


    return (
        <>
            {loading ? <SpinLoader /> :
                <Space direction="vertical" size="middle" className="w-100">
                    {isConsultation() ? <Divider orientation="left">{t('agent.user-details-form.title.consultation')}</Divider>
                        : isUpdate() ? <Divider orientation="left">{t('agent.user-details-form.title.mise-a-jour')}</Divider>
                            : <Divider orientation="left">{t('agent.user-details-form.title.ajout')}</Divider>}

                    {error &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                            <Col span={24}>
                                <Alert
                                    style={{ textAlign: 'center', width: '100%' }}
                                    message={t(error)}
                                    type="error"
                                    closable
                                />
                            </Col>
                        </Row>
                    }
                    {success &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                            <Col span={24}>
                                <Alert
                                    style={{ textAlign: 'center', width: '100%' }}
                                    message={t(success)}
                                    type="success"
                                    closable
                                />
                            </Col>
                        </Row>
                    }


                    <Card >
                        <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
                            <Space direction="vertical" size="middle" className="w-100">
                                <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                                    <Form form={form}
                                        name="create-user"
                                        style={{ width: '100%' }}
                                        size='small'
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 14 }}
                                        onFinish={onFinish}
                                        layout="horizontal"
                                        scrollToFirstError
                                        initialValues={getInitData()}
                                    >
                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Col span={11}>
                                                <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={isConsultation()} required={true} name="first_name" label={t('agent.user-details-form.form.nom.label')} placeholder={t('agent.user-details-form.form.nom.placeholder')} errorMessage={t('agent.user-details-form.form.nom.error-message')} />
                                            </Col>
                                            <Divider type="vertical" />
                                            <Col span={11}>
                                                <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={isConsultation()} required={true} name="last_name" label={t('agent.user-details-form.form.prenom.label')} placeholder={t('agent.user-details-form.form.prenom.placeholder')} errorMessage={t('agent.user-details-form.form.prenom.error-message')} />
                                            </Col>
                                        </Row>

                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Col span={11}>
                                                <InputEmailWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={isConsultation()} required={true} name="email" label={t('agent.user-details-form.form.email.label')} placeholder={t('agent.user-details-form.form.email.placeholder')} errorMessage={t('agent.user-details-form.form.email.error-message')} />
                                            </Col>
                                            <Divider type="vertical" />
                                            <Col span={11}>
                                                <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={isConsultation() || isUpdate()} required={true} name="login" label={t('agent.user-details-form.form.login.label')} placeholder={t('agent.user-details-form.form.login.placeholder')} errorMessage={t('agent.user-details-form.form.login.error-message')} />
                                            </Col>
                                        </Row>

                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                             <Col span={11}>
                                                <SeletWithDoubleLabel disabled={isConsultation()} required={true} name="operateur" label={t('agent.user-details-form.form.operateur.label')} placeholder={t('agent.user-details-form.form.operateur.placeholder')} options={Utils.Operateurs} errorMessage={t('agent.user-details-form.form.operateur.error-message')} />
                                            </Col>
                                            <Divider type="vertical" />
                                            <Col span={11}>
                                                <InputPhoneNumberWithDoubleLabel disabled={isConsultation()} required={true} name="telephone" label={t('agent.user-details-form.form.telephone.label')} placeholder={t('agent.user-details-form.form.telephone.placeholder')} errorMessage={t('agent.user-details-form.form.telephone.error-message')} />
                                            </Col> 
                                        </Row>

                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Col span={11}>
                                                <SeletWithDoubleLabel disabled={isConsultation()} required={true} name="profile" label={t('agent.user-details-form.form.profil.label')} placeholder={t('agent.user-details-form.form.profil.placeholder')} options={profiles ?? []} onChangeAction={onChangeProfil} errorMessage={t('agent.user-details-form.form.profil.error-message')} />
                                            </Col>
                                        </Row>

                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Col span={11}>
                                            </Col>
                                        </Row>

                                        {isProvinceVisible() &&
                                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                                <Col span={11}>
                                                    <SeletWithDoubleLabel disabled={isConsultation()} required={true} name="region" label={t('agent.user-details-form.form.region.label')} placeholder={t('agent.user-details-form.form.ville.placeholder')} options={params.regions ?? []} onChangeAction={onChangeRegion} errorMessage={t('agent.user-details-form.form.region.error-message')} />
                                                </Col>
                                                <Divider type="vertical" />
                                                <Col span={11}>
                                                    <SeletMultipleWithDoubleLabel disabled={isConsultation()} required={true} name="province_ids" label={t('agent.user-details-form.form.province.label')} placeholder={t('agent.user-details-form.form.province.placeholder')} options={provinces ?? []} onChangeAction={onChangeProvince} errorMessage={t('agent.user-details-form.form.province.error-message')} />
                                                </Col>
                                            </Row>
                                        }

                                        {!isConsultation() &&
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'right' }}>
                                                    <Button shape='round' type="primary" htmlType="submit" size='large' >
                                                        {action && id ? t('agent.user-details-form.form.submit.modif') : t('agent.user-details-form.form.submit.ajout')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Form>
                                </Col>
                            </Space>
                        </Row>

                    </Card>

                </Space>
            }
        </>
    )
}

export default UserDetail;
