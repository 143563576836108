import types from './ActionTypes';
import Api from '../../../utilities/services/Api';


export const getUsersBySearchCriteria = (searchCiteria) => {
  return ({
    type: types.GET_USERS_BY_SEARCH_CRITERIA,
    payload: Api.user.searchUsers(searchCiteria),
  })
};

export const getUserById = (id) =>
({
  type: types.GET_USER_BY_ID,
  payload: Api.user.getUserById(id),
});

export const createUser = (payload) => ({
  type: types.ADD_USER,
  payload: Api.user.addUser(payload),
});

export const updateUser = (id, payload) => ({
  type: types.UPDATE_USER,
  payload: Api.user.updateUser(id, payload),
});

export const deleteUserById = (id) => ({
  type: types.DELETE_USER,
  payload: Api.user.deleteUserById(id),
});
export const deactivateUserById = (id) => ({
  type: types.DELETE_USER,
  payload: Api.user.deactivateUserById(id),
});

export const resetMessages = () => {
  return ({
    type: types.RESET_MESSAGES,
  })
};