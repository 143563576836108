import { Alert, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react"
import ArtisanList from "./components/ArtisanList/ArtisanList";
import ArtisanSearchForm from "./components/ArtisanSearchForm/ArtisanSearchForm";
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { getGlobalParam } from '../../GlobalParams/Action';
import { getReferentiels } from '../../Referentiels/Action';
import { getArtisanBySearchCriteria, radiateRnaAccountById } from './Action';
import Utils from '../../../utilities/Utils/Utils';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const RadiationArtisanAccount = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const artisans = useSelector(state => state.artisanReducer?.artisans);
    const loading = useSelector(state => state.artisanReducer?.loading);
    const paramsLoading = useSelector(state => state.globalParamReducer?.loading);

    const artisanStatutsLoading = useSelector(state => state.referentielReducer?.loading);
    const params = useSelector(state => state.globalParamReducer?.params);
    const artisanStatuts = useSelector(state => state.referentielReducer?.params?.artisanStatuts);
    const profileCode = useSelector(state => state.loginReducer?.profil?.code);
    const niveau = useSelector(state => state.loginReducer?.profil?.niveau);
    const provinces = useSelector(state => state.loginReducer.user?.userProvinces?.map(prov => prov.id));
    const location = useLocation();
    const [successeMessage, setSuccesseMessage] = useState("");
    const [searchCriteria, setSearchCriteria] = useState({})
    const [errorMessage, setErrorMessage] = useState("");
    const artisanListMetadata = useSelector(state => state.artisanReducer?.artisanListMetadata);
    const [pagination, setPaginattion] = useState(Utils.DEFAULT_PAGINATION)
    const [sort, setSort] = useState(false)
    const [filters, setFilters] = useState({})
    const [searchMetadata, setSearchMeataData] = useState(Utils.PAGE_SEARCH_DEFAULT);


    useEffect(() => {
        dispatch(getGlobalParam())
        dispatch(getReferentiels());
        searchArtisan();
    }, [location]);

    const searchArtisan = (values, searchMetaCriteria) => {
        let searchcriteria = { ...values };
        if (values && values.province) {
            searchcriteria = { ...searchcriteria, province: [searchcriteria.province] }
        } else if (niveau === Utils.USER_LEVEL.LOCAL) {
            searchcriteria = { ...searchcriteria, province: provinces }
        }
        dispatch(getArtisanBySearchCriteria({ ...searchcriteria }, searchMetaCriteria));
    }

    const radiateRnaAccount = artisanId => {
        dispatch(radiateRnaAccountById(artisanId)).then(() => {
            setSuccesseMessage(t('agent.radiationArtisanAccount.message.okRadiation'))
        }
        ).catch(() => {
            setErrorMessage(t('agent.radiationArtisanAccount.message.koRadiation'))

        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const searchMetaData = {
            page: pagination?.current,
            pageSize: pagination?.pageSize,
            withCount: true,
            sortField: sorter?.columnKey === 'dateDemandeInscription' ? 'date_demande_inscription' : sorter?.columnKey,
            sortDirection: sorter?.order,
        };
        setSearchMeataData(searchMetaData)
        setPaginattion(pagination);
        setSort(sorter);
        setFilters(filters);
        searchArtisan(searchCriteria, searchMetaData);
    };
    return (
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {paramsLoading || artisanStatutsLoading
                ? <SpinLoader />
                : <>
                    <ArtisanSearchForm params={params} setSearchCriteria={setSearchCriteria} artisanStatuts={artisanStatuts} searchArtisan={searchArtisan} />
                    {errorMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={errorMessage}
                                    type="error"
                                    closable
                                    onClose={() => setErrorMessage("")}
                                />
                            </Col>
                        </Row>
                    }
                    {successeMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={successeMessage}
                                    type="success"
                                    onClose={() => setSuccesseMessage("")}
                                    closable
                                />
                            </Col>
                        </Row>
                    }
                    {loading
                        ? <SpinLoader />
                        : <div className="search-result-list">

                            <ArtisanList
                                artisans={artisans}
                                artisanListMetadata={artisanListMetadata}
                                searchCriteria={searchCriteria}
                                pagination={pagination}
                                filters={filters}
                                handleTableChange={handleTableChange}
                                radiateRnaAccount={radiateRnaAccount} />
                        </div>
                    }
                </>}
        </Space>
    );
}

export default RadiationArtisanAccount;