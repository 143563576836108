import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';


export const getArtisanFile = (artisanId, documentId) => {
  return ({
    type: types.GET_FILE,
    payload: Api.accounts.getArtisanFile(artisanId, documentId),
  })
};

export const getArtisanFilesList = (artisanId) => {
    return ({
      type: types.GET_FILES_LIST,
      payload: Api.accounts.getArtisanFilesList(artisanId),
    })
};

export const uploadCommiteFile = (artisanId, document) => {
  return ({
    type: types.UPLOAD_COMMITE_FILE,
    payload: Api.commite.uploadFile(artisanId, document),
  })
};

export const removeCommiteFile = (artisanId, documentId) => {
  return ({
    type: types.REMOVE_COMMITE_FILE,
    payload: Api.commite.removeFile(artisanId, documentId),
  })
};

export const getCommiteFiles = (artisanId) => {
  return ({
    type: types.GET_ALL_COMMITE_FILES,
    payload: Api.commite.getFiles(artisanId),
  })
};

export const getCommiteFile = (artisanId,documentId) => {
  return ({
    type: types.GET_ONE_COMMITE_FILE,
    payload: Api.commite.getFile(artisanId,documentId),
  })
};


export const getArtisanEvents = (artisanId) => {
  return ({
    type: types.GET_ACTIONS,
    payload: Api.artisan.getArtisanEvents(artisanId),
  })
};

export const changeArtisanPhoneNumber = (userId, payload) => {
  return ({
    type: types.CHANGE_ARTISAN_PHONE_NUMBER,
    payload: Api.artisan.changeArtisanPhoneNumber(userId, payload),
  })
};

export const changeArtisanPhoneNumberClear = (userId, payload) => {
  return ({
    type: types.CHANGE_ARTISAN_PHONE_NUMBER_CLEAR,
    payload: {},
  })
};

export const reinitializeAccount = (userId, payload) => {
  return ({
    type: types.REINITIALIZE_ARTISAN_ACCOUNT,
    payload: Api.artisan.reinitializeAccount(userId, payload),
  })
};

export const reinitializeAccountClear = (userId, payload) => {
  return ({
    type: types.REINITIALIZE_ACCOUNT_CLEAR,
    payload: {},
  })
};
