
import React from "react";
import { Content } from "antd/lib/layout/layout";
import { Col } from "antd";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import Settings from './Settings/Settings'
import Sms from './Sms/Sms'
import Logs from './administration/logs/Logs'
import Metrics from './administration/metrics/Metrics'
import Artisan from './Artisan/Artisan'
import ChangerPass from "../ChangePasse/ChangerPass";
import ListeReclamation from "../Reclamation/ListeReclamation/ListeReclamation";
import ReclamationDetails from "../Reclamation/ReclamationDetails/ReclamationDetails";
import Users from './Users/Users'
import Habilitation from './Habilitation/Habilitation'
import ProfileDetail from './Habilitation/components/ProfileDetail/ProfileDetail'
import Referentiels from './Referentiels/Referentiels'
import UserDetail from './Users/components/UserDetail/UserDetail'
import ArtisanDetails from './Artisan/components/ArtisanDetails/ArtisanDetails'
import { useTranslation } from 'react-i18next';
import Report from "./report/Report";
import Utils from "../../utilities/Utils/Utils";
import { useSelector } from "react-redux";
import DeleteArtisanAccount from "./DeleteArtisanAccount/DeleteArtisanAccount";
import RadiationArtisanAccount from "./RadiationArtisanAccount/RadiationArtisanAccount";

const AgentApp = props => {
  //const { path } = useRouteMatch();
  const { t } = useTranslation();
  const path = "/app/agent";

  const profileCode = useSelector(state => state.loginReducer?.profil?.code);

  return (<>
    <Content className="site-layout-background"
      style={{
        margin: "24px 20px",
        padding: 24,
        minHeight: 280,
      }}
    >
      <Switch>
        <Route exact path={`${path}/reclamations`} component={ListeReclamation} />
        <Route exact path={`${path}/reclamations/:id`} component={ReclamationDetails} />
        <Route exact path={`${path}/changer-pass`} component={ChangerPass} />
        <Route exact path={`${path}/habilitation/`} component={Habilitation} />
        <Route exact path={`${path}/habilitation/add`} component={ProfileDetail} />
        <Route exact path={`${path}/referentiel/:refType`} component={Referentiels} />
        <Route exact path={[`${path}/users/add`, `${path}/users/:id/:action`]} component={UserDetail} />
        <Route exact path={`${path}/search/:id/details`} component={ArtisanDetails} />
        <Route exact path={`${path}/logs`} component={Logs} />
        <Route exact path={`${path}/sms`} component={Sms} />
        <Route exact path={`${path}/metrics`} component={Metrics} />
        <Route exact path={`${path}/report`} component={Report} />
        <Route exact path={`${path}/delete-rna-account`} component={DeleteArtisanAccount} />
        <Route exact path={`${path}/radiate-rna-account`} component={RadiationArtisanAccount} />
        <Route exact path={`${path}/settings`} component={Settings} />

        {
          profileCode === Utils.AGENT_PROFILES.ADMIN ? <>
            <Route exact path={[`${path}/artisans`, `${path}/search`]} component={Artisan} />
            <Route exact path={[`${path}/home`, `${path}/users/`]} component={Users} />
          </> : <>
            <Route exact path={[`${path}/home`, `${path}/artisans`, `${path}/search`]} component={Artisan} />
            <Route exact path={`${path}/users/`} component={Users} />
          </>
        }

        <Redirect to={`${path}/home`} />
      </Switch>
    </Content>
    <Col span={24} type="flex" justify="center" align="center" style={{ margin: '0', justifyContent: "center", padding: "20px 0 20px 0" }} >
      <h4 style={{ color: "white" }}>{t('new-account.footer')}</h4>
    </Col>
  </>)

}
export default AgentApp;
