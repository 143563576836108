import types from './ActionTypes';
import Api from '../../../utilities/services/Api';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getAllSettings = () => ({
  type: types.GET_ALL_SETTING,
  payload: Api.settings.getAll()
});

export const updateSettings = (code,payload) => ({
  type: types.UPDATE_SETTING,
  payload: Api.settings.update(code,payload)
});
