import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getPublicPrive = () => ({
  type: types.GET_PUBLIC_PRIVE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.PUBLIC_PRIVE),
});
export const updatePublicPrive = (id, paylaod) => ({
  type: types.UPDATE_PUBLIC_PRIVE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addPublicPrive = (paylaod) => {
  return {
    type: types.ADD_PUBLIC_PRIVE,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};