import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getDiplome = () => ({
  type: types.GET_DIPLOME,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME),
});
export const getDuree = () => ({
  type: types.GET_DUREE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DUREE),
});
export const getDiplomeDuree = () => ({
  type: types.GET_DIPLOME_DUREE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME_DUREE),
});
export const updateDiplomeDuree = (id, paylaod) => ({
  type: types.UPDATE_DIPLOME_DUREE,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addDiplomeDuree = (paylaod) => {
  return {
    type: types.ADD_DIPLOME_DUREE,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};