import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllArtisanDeleteDemande, handelDeleteArtisanAccount, updateDemandeSuppList } from './Action'
import Title from "antd/lib/typography/Title";
import DeleteArtisanAccountList from "./components/DeleteArtisanAccountList";
import DeleteArtisanAccountSearchForm from "./components/DeleteArtisanAccountSearchForm";
import { getGlobalParam } from "../../GlobalParams/Action";

const DeleteArtisanAccount = props => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const data = useSelector(state => state.deleteArtisanAccountReducer?.deleteArtisanAccounts);
    const params = useSelector(state => state.globalParamReducer?.params);
    const errorCode = useSelector(state => state.deleteAccountReducer?.error);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        dispatch(getGlobalParam())
            .then( () =>  dispatch(getAllArtisanDeleteDemande({ statut: "INITIE" })));
        
    }, [])

    const searchDeleteArtisanAccount = payload => {
        dispatch(getAllArtisanDeleteDemande(payload));
    }

    const handleRejectRequest = (artisanId, demandeSuppressionId, payload) => {
        dispatch(handelDeleteArtisanAccount(artisanId, demandeSuppressionId, payload)).then(
            () => {
                setSuccesseMessage(t('agent.deleteArtisanAccount.message.okhandleRejectRequest'))
                dispatch(updateDemandeSuppList(demandeSuppressionId))
            }
        ).catch(() => {
            setErrorMessage(t(errorCode || 'agent.deleteArtisanAccount.message.kOhandleRejectRequest'))

        })
            ;
    }

    const handleValidateRequest = (artisanId, demandeSuppressionId, payload) => {
        dispatch(handelDeleteArtisanAccount(artisanId, demandeSuppressionId, payload)).then(
            () => {
                setSuccesseMessage(t('agent.deleteArtisanAccount.message.okhandleValidateRequest'))
                dispatch(updateDemandeSuppList(demandeSuppressionId))
            }
        ).catch(() => {
            setErrorMessage(t(errorCode || 'agent.deleteArtisanAccount.message.kOhandleValidateRequest'))
        })
            ;
    }

    return (
        <>
            <Title>{t('agent.deleteArtisanAccount.title')}</Title>
            <Row>
                <Col span="24">
                    <DeleteArtisanAccountSearchForm params={params} searchDeleteArtisanAccount={searchDeleteArtisanAccount} />
                </Col>
                <Col span='24'>
                    {errorMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={errorMessage}
                                    type="error"
                                    closable
                                    onClose={() => setErrorMessage("")}
                                />
                            </Col>
                        </Row>
                    }
                    {successeMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={successeMessage}
                                    type="success"
                                    onClose={() => setSuccesseMessage("")}
                                    closable
                                />
                            </Col>
                        </Row>
                    }
                    <DeleteArtisanAccountList data={data} loadingTable={false} handleRejectRequest={handleRejectRequest} handleValidateRequest={handleValidateRequest} />
                </Col>
            </Row>
        </>
    )
}

export default DeleteArtisanAccount;