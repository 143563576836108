import types from './ActionTypes';
import Api from '../../../../../utilities/services/Api';
import Utils from '../../../../../utilities/Utils/Utils';


export const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export const getDiplomeDistinct = () => ({
  type: types.GET_DIPLOME_DISTINCT,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME_DISTINCT),
});
export const updateDiplomeDistinct = (id, paylaod) => ({
  type: types.UPDATE_DIPLOME_DISTINCT,
  payload: Api.referentiels.referentiel.update(id, paylaod),
});
export const addDiplomeDistinct = (paylaod) => {
  return {
    type: types.ADD_DIPLOME_DISTINCT,
    payload: Api.referentiels.referentiel.add(paylaod),
  }
};